export function getDocumentId(id: string) {
  switch (id) {
    case 'logo':
      return 'logo';
    case 'stamp':
      return 'stamp';
    case 'idCardAuthorityIdentitySignature':
      return 'idCardSignature';
    case 'leavingCertificateAuthorityIdentitySignature1':
      return 'leavingCertcSignature1';
    case 'leavingCertificateAuthorityIdentitySignature2':
      return 'leavingCertcSignature2';
    case 'bonafideCertificateAuthorityIdentitySignature1':
      return 'bonafiedCertSignature1';
    case 'bonafideCertificateAuthorityIdentitySignature2':
      return 'bonafiedCertSignature2';
    case 'feeReceiptAuthorityIdentitySignature':
      return 'feeReceiptSignature';
  }
}